<template>
  <div>
    <!-- select 2 demo -->
    <b-modal id="modal-login" centered ok-only no-close-on-backdrop hide-footer="true" title="Add News">
      <b-form>
        <b-row>
          <b-col>
            <b-form-group ref="image" label="Upload Profile" invalid-feedback="Profile pic is required.">
              <b-media no-body>
                <div v-if="logoloading == true">
                  <b-spinner variant="primary" type="grow" class="m-5" label="Spinning"></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img ref="previewEl" rounded
                      src="https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg"
                      @click="$refs.fileProfile.click()" v-if="logoloading == false"
                      style="height: 150px; width: 150px; object-fit: cover" />

                    <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div>
                      <i @click="deleteLogo()" style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"></i>
                    </div>
                    <b-img rounded :src="plus" style="height: 150px; width: 150px; object-fit: cover" />
                    <br />
                  </b-link>

                  <input type="file" id="fileProfile" hidden ref="fileProfile" @change="selectlogo()" accept="images/*" />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col>
            <b-form-group
              ref="cover"
              label="Upload Cover"
              invalid-feedback="Cover pic is required."
            >
              <b-media no-body>
                <div v-if="coverLoading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      rounded
                      src="https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg"
                      @click="$refs.coverProfile.click()"
                      v-if="coverLoading == false"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />

                  </b-link>

                  <b-link v-if="coverLoading == 'loaded'">
                    <div>
                      <i
                        @click="deleteCover()"
                        style="margin-top: 70px; margin-left: 70px"
                        class="fa fa-trash fa-md text-danger position-absolute"
                      ></i>
                    </div>
                    <b-img
                      rounded
                      :src="cover"
                      style="height: 150px; width: 150px; object-fit: cover"
                    />
                    <br />
                  </b-link>

                  <input
                    type="file"
                    id="coverProfile"
                    hidden
                    ref="coverProfile"
                    @change="selectCover()"
                    accept="images/*"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-col> -->
          <b-col class="scrolling-wrapper">
            <div v-for="(img, index) in moreImg" :key="index" class="moreImg">
              <feather-icon icon="XIcon" size="18" color="red" @click="deletemore(index)"
                style="position:absolute; right: 0px; top: 0px; cursor: pointer" />
              <b-img rounded :src="img" style="height: 150px; width: 150px; object-fit: cover; margin-inline: 6px" />
            </div>
            <input multiple type="file" id="morefile" hidden ref="morefile" @change="moreImages()" accept="images/*" />
            <b-button @click="$refs.morefile.click()" variant="primary">
              <b-spinner v-if="moreloading" variant="light" type="grow" small label="Spinning"></b-spinner>
              <span v-if="!moreloading" class="text-nowrap">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="text-nowrap">More</span>
              </span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <small class="text-muted">Recommended pixels are 850 x 950</small>
          </b-col>
        </b-row>
        <b-form-group label="Name" invalid-feedback="name is required.">
          <b-form-input id="name-input" ref="name" placeholder="Enter name here." required @focusout="CheckName()"
            v-model="myObj.name" />
        </b-form-group>
        <b-form-group label="Title" invalid-feedback="Title is required.">
          <b-form-input id="name-input" ref="title" placeholder="Enter Title here." required @focusout="CheckTitle()"
            v-model="myObj.title" />
        </b-form-group>
        <b-form-group label="Details" invalid-feedback="details is required.">
          <b-form-input id="name-input" ref="details" placeholder="Enter details here." required
            @focusout="CheckDetails()" v-model="myObj.details" />
        </b-form-group>
        <b-form-group label="Date" ref="date" invalid-feedback="date is required.">
          <flat-pickr ref="date" v-model="myObj.date" class="form-control" placeholder="Select date."
            @on-change="CheckDate()" />
        </b-form-group>
        <b-form-group label="Time" invalid-feedback="time is required.">
          <b-form-input id="name-input" ref="time" placeholder="Enter time here." required @focusout="CheckTime()"
            v-model="myObj.time" />
        </b-form-group>
        <b-form-group v-if="isUpdating" label="Status" invalid-feedback="Status is required." ref="status">
          <v-select placeholder="Select status." v-model="myObj.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions" :clearable="false"
            @input="CheckStatus()" />
        </b-form-group>
      </b-form>
      <br />
      <div style="">
        <b-button class="float-right ml-5" variant="primary" @click="AddFlavour()">
          <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
          <span v-if="!request" class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <!-- <b-form-group label="Search Business Type" invalid-feedback="Search field">
      <b-form-input
        id="name-input"
        ref="name"
        placeholder="Enter business name to search"
        required
        @input="SearchData()"
        v-model="searchQuery"
      />
    </b-form-group> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Add Flavour -->
          <b-col class="mt-2" md="10" sm="8">
            <div class="d-flex align-items-center justify-content-start">
              <div style="padding-right: 15px">
                <b-button @click="OpenAddModal(0)" variant="primary">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="text-nowrap">Add News</span>
                </b-button>
              </div>
            </div>
          </b-col>
          <!-- <b-col md="2" cols="4">
            <label for="">Filter</label>
            <v-select
              placeholder="Select status."
              v-model="filterStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              @input="FilterTypes()"
              :clearable="false"
            />
          </b-col> -->
        </b-row>
      </div>

      <div>
        <b-table :items="items" :fields="fields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(profilePic)="data">
            <b-img :src="data.value" style="width: 100px; height: 100px; object-fit: cover" rounded alt="Rounded image" />
          </template>

          <template #cell(cover)="data">
            <b-img :src="data.value" style="width: 100px; height: 100px; object-fit: cover" rounded alt="Rounded image" />
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon"
                v-b-tooltip.hover.v-primary v-b-tooltip.placement.left title="Edit" @click="OpenAddModal(data.item.id)">
                <feather-icon size="16" icon="EditIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" class="ml-1 btn-icon"
                v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Delete" size="16"
                @click="DeleteFlavour(data.item.id)">
                <feather-icon size="16" icon="XIcon" />
              </b-button>
            </template>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalOrders" :per-page="perPage" first-number last-number
                class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BEmbed,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    //Multiselect,
    BEmbed,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    //BAvatar,
    BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData();
  },
  data() {
    return {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus: "",
      fileProfile: "",
      logoloading: false,
      cover: "",
      coverProfile: "",
      coverLoading: false,
      moreloading: false,
      morefile: null,
      errors: {
        status: false,
      },
      statusOptions: ["active", "inactive"],
      index: null,
      fields: [
        "#",
        { label: "profile", key: "profilePic" },
        { label: "cover", key: "cover" },
        { label: "name", key: "name" },
        { label: "Title", key: "title" },
        { label: "details", key: "details" },
        { label: "date", key: "date" },
        { label: "time", key: "time" },
        "actions",
      ],
      filterStatus: "",
      items: [],
      request: false,
      myObj: {
        id: 0,
        profilePic: "",
        name: "",
        title: "",
        cover: "",
        details: "",
        date: "",
        time: "",
        postedOn: "",
        status: "active",
        moreImages: "",
      },
      searchQuery: "",
      isUpdating: false,
      moreImg: [],
    };
  },
  methods: {
    SearchData() {
      if (this.searchQuery.length > 0) {
        var data = this.items.filter(
          (data) =>
            JSON.stringify(data)
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1
        );
        this.items = data;
      } else {
        this.LoadData();
      }
    },
    FilterTypes() {
      var axios = require("axios");

      if (this.filterStatus == "All") {
        var config = {
          method: "get",
          url: "https://waqta.appick.io/api/business",
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            this.items = response.data.data;
            // response.data.data.forEach(e => {
            //   e.marketplaceShare = e.marketplaceShare * 100;
            //   this.items.push(e);
            // });

            // console.log(this.items);
            //console.log(this.businesses);
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        var config = {
          method: "get",
          url:
            "https://waqta.appick.io/api/business/LoadData?status=" +
            this.filterStatus,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            this.items = response.data.data;
            // response.data.data.forEach(e => {
            //   e.marketplaceShare = e.marketplaceShare * 100;
            //   this.items.push(e);
            // });

            // console.log(this.items);
            //console.log(this.businesses);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
      // this.$store.state.userData.userID
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      // console.log(this.myObj.date);
      var elem = this.$refs["date"];
      if (this.myObj.date == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTime() {
      var elem = this.$refs["time"];
      if (this.myObj.time == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCover() {
      console.log(this.myObj.cover);
      var elem = this.$refs["cover"];
      if (this.myObj.cover == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDetails() {
      var elem = this.$refs["details"];
      if (this.myObj.details == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTitle() {
      var elem = this.$refs["title"];
      if (this.myObj.title == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      console.log(this.myObj.profilePic);
      var elem = this.$refs["image"];
      if (this.myObj.profilePic == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckStatus() {
      if (this.isUpdating) {
        var elem = this.$refs["status"];
        if (this.myObj.status == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    OpenAddModal(id) {
      if (id != 0) {
        var axios = require("axios");
        var config = {
          method: "get",
          url: "https://api.geotalent.co/api/News/" + id,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            console.log(response);
            this.myObj = response.data.data;
            this.plus = this.myObj.profilePic;
            this.logoloading = "loaded";
            this.cover = this.myObj.cover;
            this.coverLoading = "loaded";
            this.isUpdating = true;
            console.log(this.myObj.moreImages);
            if (
              this.myObj.moreImages === null ||
              this.myObj.moreImages === ""
            ) {
              this.moreImg = [];
            } else {
              this.moreImg = this.myObj.moreImages.split(",");
              console.log(this.moreImg);
            }
            this.$bvModal.show("modal-login");
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        this.myObj = {
          id: 0,
          profilePic: "",
          name: "",
          title: "",
          cover: "",
          details: "",
          date: "",
          time: "",
          postedOn: "2022-12-31T11:49:04.912Z",
          status: "active",
          moreImages: "",
        };
        this.logoloading = false;
        this.plus =
          "https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg";
        this.coverLoading = false;
        this.cover =
          "https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg";
        this.isUpdating = false;
        this.moreImg = [];
        this.$bvModal.show("modal-login");
      }
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/News/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                Swal.fire("Success!", "News has been deleted.", "success").then(
                  (res) => {
                    this.LoadData();
                  }
                );
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    moreImages() {
      this.morefile = this.$refs.morefile.files;
      console.log(this.morefile);
      if (this.morefile.length !== 0) {
        this.moreloading = true;
        let formData = new FormData();
        this.morefile.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://Geoupload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            e.data.myresp.forEach((el) => {
              this.moreImg.push(el.path);
              this.myObj.moreImages = this.moreImg.join(",");
            });
            this.moreloading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    selectlogo() {
      // "https://geofiles.appick.io/"
      this.fileProfile = this.$refs.fileProfile.files[0];
      console.log(this.fileProfile);
      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://Geoupload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            this.plus = fn1;
            this.myObj.profilePic = this.plus;
            // console.log(this.plus);
            this.logoloading = "loaded";
            this.CheckImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    selectCover() {
      this.coverProfile = this.$refs.coverProfile.files[0];
      console.log(this.coverProfile);
      var axios = require("axios");
      if (this.coverProfile !== "") {
        this.coverLoading = true;
        let formData = new FormData();
        formData.append("file", this.coverProfile);
        axios
          .post("https://Geoupload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            this.cover = fn1;
            this.myObj.cover = this.cover;
            this.coverLoading = "loaded";
            this.CheckCover();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.profilePic = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    deletemore(ind) {
      this.moreImg.splice(ind, 1);
      this.myObj.moreImages = this.moreImg.join(",");
      // console.log(this.myObj.moreImages.split(","));
    },
    deleteCover() {
      this.myObj.cover = "";
      this.coverLoading = false;
      this.$refs.coverProfile.value = null;
    },
    AddFlavour() {
      this.CheckImage();
      this.CheckName();
      this.CheckStatus();
      this.CheckTitle();
      // this.CheckCover();
      this.CheckDetails();
      this.CheckDate();
      this.CheckTime();
      if (
        this.CheckImage() == false ||
        this.CheckName() == false ||
        this.CheckTitle() == false ||
        // this.CheckCover() == false ||
        this.CheckDetails() == false ||
        this.CheckDate() == false ||
        this.CheckTime() == false ||
        this.CheckStatus() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        // this.moreImg.unshift(this.myObj.cover);
        // this.myObj.moreImages = this.moreImg.join(",");
        this.myObj.cover = this.moreImg[0];
        console.log("Obj", this.myObj);
        this.request = true;
        if (this.myObj.id == 0) {
          var axios = require("axios");
          var config = {
            method: "post",
            url: "https://api.geotalent.co/api/News/AddNews",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                console.log(response.data);
                Swal.fire("Success!", "News added.", "success").then((res) => {
                  this.$bvModal.hide("modal-login");
                  this.request = false;
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          //Edit
          console.log("edit_Obj", this.myObj);
          this.request = true;
          var axios = require("axios");
          // this.$store.state.userData.userID
          var config = {
            method: "put",
            url: "https://api.geotalent.co/api/News/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                this.$bvModal.hide("modal-login");
                this.request = false;
                this.LoadData();
                this.$bvToast.toast("News updated.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-bottom-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      }
    },
    LoadData() {
      // console.log("userId",this.$store.state.userData)
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/News/LoadNews",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.items = response.data.data;
          this.items.forEach((el) => {
            el.date = el.date.split("T")[0];
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.scrolling-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper .moreImg {
  flex: 0 0 auto;
  position: relative;
}
</style>
